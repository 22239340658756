.carsales .site-nav-wrapper {
    border-top: 7px solid #0073ba;
}

section.hero-section {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    background: #151515;
    height: 70vh;
    min-height: 480px;

    .demotores &, .chileautos &, .soloautos & {
        height: 510px;
    }

    @media (max-width: 800px) {
        height: 40vh;
    }

    .search-form-container {
        position: absolute;
        left: 0;
        top: 32%;
        width: 100%;
        max-width: 100%;
        pointer-events: none;
        z-index: 99;
    }
}

section.main-section {
    margin-top: 30px;
    overflow: hidden;

    .bar {
        overflow-x: auto;
    }
}

section.offerings-section {
    background: #f2f2f2;
    margin-top: 20px;
}


#editorial-container {
    margin-top: 30px;
}

@media (max-width: 1024px) {
    .csn-theme {
        .container {
            max-width: none;
        }

        .card-slider-item {
            padding: 0 5px;

            .card-body {
                padding: 15px;
            }
        }
    }
}

.csn-theme.site-nav-wrapper.unmissable {
    top: 50px;
}

body.homepage-border .site-nav-wrapper {
    .site-nav.unmissable:before {
        top: 135px;
    }
}

.redbookcars, .redbookbikes, .redbookboats {
    section.hero-section {
        margin-top: 85px;
    }

    section.offerings-section {
        background: #fff;
    }

    .search-form-container .search-form-col .search-form{
        max-height: 260px;
    }
}
